/* New styles for the top banner and image */
.top-banner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 10px; /* Very thin div */
  background-image: url('/public/gradient.png');
  background-size: cover;
  z-index: 10; /* Reduced z-index as it's no longer fixed */
}

.App {
  position: relative; /* Add this to contain absolute positioned children */
  text-align: center;
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 20px;
  padding-top: 15%; /* Adjust to account for top banner, image, and some spacing */
  background-image: url('/public/globe.png');
  background-size: cover; /* Make the background image cover the entire container */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
}

.App-header {
  padding-bottom: 20px;
  display: flex;
  justify-content: center; /* Center the logo horizontally */
}

.dropp-logo {
  height: 60px; /* Increased logo size */
  margin-bottom: 20px;
}

.experience-grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 350px; /* Made experiences smaller */
  margin: 0 auto; /* Center the grid */
}

.experience-card {
  border-radius: 20px;
  overflow: hidden;
  background-color: #1a1a1a;
}

.experience-image {
  height: 100px; /* Slightly smaller image height */
  background-size: cover;
  background-position: center;
}

.experience-content {
  position: relative;
  padding: 30px 20px 20px;
  text-align: left;
}

.experience-logo {
  position: absolute;
  top: -25px;
  left: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #1a1a1a;
}

.experience-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.experience-card h2 {
  font-size: 1.1rem; /* Slightly smaller font */
  margin-bottom: 5px;
  font-weight: bold;
}

.experience-card p {
  font-size: 0.85rem; /* Slightly smaller font */
  margin-bottom: 15px;
  opacity: 0.8;
}

.experience-card button {
  background-color: #FFD700;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.experience-card button:hover {
  background-color: #FFC700;
}

.experience-card button.clicked {
  transform: scale(0.95);
  background-color: #FFB700;
}

footer {
  margin-top: auto;
  padding-top: 20px;
  font-size: 0.8rem;
  opacity: 0.7;
}

/* For PC: two-column layout */
@media (min-width: 768px) {
  .experience-grid {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 750px; /* Adjusted for smaller cards */
  }

  .experience-card {
    width: calc(50% - 10px); /* 50% width minus half of the gap */
  }
}
